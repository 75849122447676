import { Link } from "react-router-dom";
import Loginbtn from "./loginbtn";
import logo2 from "../media/logo2.svg";

//Simple header... ...
//Didn't use on products, search and product pages to avoid prop-drilling.

const Header = ({ token, setToken }) => {
  return (
    <div className="header">
      <Link to="/products">
        <img className="logo2" src={logo2} alt="logo"></img>
      </Link>

      <div className="navpan">
        <Loginbtn token={token} setToken={setToken} />
      </div>
    </div>
  );
};

export default Header;
