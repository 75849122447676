//Product rendering component .. takes in an array of products and loops through..
import { useNavigate } from "react-router-dom";
import Counter from "./counter";

const ProductCard = ({
  hide,
  setHide,
  myAddress,
  adult,
  setAgemodal,
  products,
  cart,
  updateCart,
  feedback,
  setFeedback,
}) => {
  const navigate = useNavigate();
  //Truncate .shorten names..text...
  const truncate = (str, length) => {
    return str.substring(0, length) + "...";
  };

  return (
    <div className="products">
      {products.map((product, index) => {
        return (
          <div
            onClick={() => navigate(`/product/${product.sku}`)}
            className="product"
            key={product._id}
          >
            <div className="prodcard-pan">
              <p className="prodcard-name">{truncate(product.name, 30)}</p>
              <p className="prodcard-price">{product.price.amount} € </p>
            </div>
            <div className="prodcard-img">
              <img
                className={`productpic-thumb ${
                  !product.thumbnail ? "hide-thumb" : ""
                }`}
                src={product.thumbnail}
                alt=""
              />
            </div>
            <div className="counter-pos">
              {product.quantity ? (
                <Counter
                  cart={cart}
                  updateCart={updateCart}
                  feedback={feedback}
                  setFeedback={setFeedback}
                  product={product}
                  adult={adult}
                  setAgemodal={setAgemodal}
                  myAddress={myAddress}
                  setHide={setHide}
                />
              ) : (
                <div className="outofstock">épuisé(e)</div>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ProductCard;
