const Agemodal = ({ setAdult, agemodal, setAgemodal }) => {
  return (
    <div className={`modal-container ${agemodal ? "" : "hide"}`}>
      <div className="modal-pan-age">
        <div className="modal-title">
          <h3>Verification de l'âge</h3>
          <p className="btn-close" onClick={() => setAgemodal(false)}>
            X
          </p>
        </div>
        <div className="age-modal-pan2">
          <p>Pour acheter de l'alcool, vous devez avoir 18 ans ou plus.</p>
          <p onClick={() => setAgemodal(false)}>J'ai moins de 18 ans.</p>
          <p
            onClick={() => {
              setAdult(true);
              setAgemodal(false);
            }}
          >
            J'ai plus de 18 ans.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Agemodal;
