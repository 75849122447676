import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Feedback from "../components/feedback";
import Header from "../components/header";
import { Elements } from "@stripe/react-stripe-js";
import StripeForm from "../components/stripeform";
import { v4 as uuidv4 } from "uuid";
import SEO from "../components/seo";

const Checkout = ({
  token,
  setToken,
  stripePromise,
  myAddress,
  cart,
  updateCart,
  setFeedback,
  feedback,
}) => {
  //Delcate necessary state..
  const navigate = useNavigate();
  const [isloading, setLoading] = useState(false);
  const [user, setUser] = useState({
    id: uuidv4(),
    firstname: "",
    lastname: "",
    email: "",
    tel: "",
  });

  //Temmporary Caution and Delivery charges vars..
  const deliveryCharges = 1.8;
  const caution = 0;

  // -------------------------------------\\
  //Calculate cart SubTotal
  const calcSubTotal = () => {
    let subtotal = 0;
    cart.forEach((item) => {
      subtotal += item.price.amount * item.orderedQty;
    });
    return subtotal;
  };

  // -------------------------------------\\
  //Calculate Total
  const calcTotal = () => {
    let total = calcSubTotal();
    total += deliveryCharges;
    total += caution;
    return total;
  };

  // ------------------------------------------------------------\\
  //Rendered Page.....

  return isloading ? (
    <div className='loading'>
      <img
        src='https://media.giphy.com/media/PUYgk3wpNk0WA/giphy.gif'
        alt='Loading'
      />
    </div>
  ) : (
    <Elements stripe={stripePromise}>
      <SEO
        title='Flink by Masood: Checkout'
        page='Checkout'
        description='Flink project by Masood @ LE REACTEUR, PARIS'
        keywords={`Flink, Le Reacteur, Paris`}
        robots={`index, follow`}
        link={`https://flink.cloudi.fr/`}
        type='website'
        creator='Masood AHMAD'
        image='https://flink.cloudi.fr/logo192.png'
      />
      <Feedback feedback={feedback} setFeedback={setFeedback} />
      <div className=''>
        {/* .....................Header................ */}
        <Header token={token} setToken={setToken} />
        {/* ------------------------------------------ */}
        <div className='container'>
          <div className='page-links'>
            <p className='page-links' onClick={() => navigate(-1)}>
              &lsaquo; Retour
            </p>
          </div>

          <div className='prod-category'>Caisse</div>
          <div className='cart-container'>
            <div className='checkout-container'>
              {/* -----------------USER INFO------------------------- */}
              <div className='checkout-info'>
                <div className='checkout-label'>Information personnelles</div>
                <input
                  className='perso-info'
                  type='text'
                  name='firstname'
                  id='firstname'
                  placeholder='Prénom'
                  onChange={(e) => (user.firstname = e.target.value)}
                />
                <input
                  className='perso-info'
                  type='text'
                  name='lastname'
                  id='lastname'
                  placeholder='Nom de famille'
                  onChange={(e) => (user.lastname = e.target.value)}
                />
                <input
                  className='perso-info'
                  type='text'
                  name='email'
                  id='email'
                  placeholder='Addresse email'
                  onChange={(e) => (user.email = e.target.value)}
                />
                <input
                  className='perso-info'
                  type='text'
                  name='tel'
                  id='tel'
                  placeholder='Numéro de téléphone'
                  onChange={(e) => (user.tel = e.target.value)}
                />
                <div className='checkout-label'>Addresse de livraison</div>
                <input
                  className='perso-info'
                  type='text'
                  name='address'
                  id='address'
                  value={myAddress.subtitle}
                  disabled
                />
              </div>
              {/* ---------------------CART--------------------- */}
              <table className='cart-table checkout-table'>
                <thead>
                  <tr>
                    <td colSpan={3}>Vos articles ({cart.length})</td>
                  </tr>
                </thead>
                <tbody>
                  {cart.length ? (
                    cart.map((article) => {
                      return (
                        <tr key={article._id}>
                          <td>
                            <img src={article.thumbnail} alt='' />
                          </td>
                          <td>{article.name}</td>
                          <td>
                            {' '}
                            <span className='qty'>{article.orderedQty}</span>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr></tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className='cartpage-caisse'>
              <div className='cartpage-labels'>
                <span>Sous-total</span>
                <span>{calcSubTotal().toFixed(2)} €</span>
              </div>
              <div className='cartpage-labels'>
                <span>Caution</span> <span>{caution.toFixed(2)} €</span>
              </div>
              <div className='cartpage-labels'>
                <span>Frais de Livraison</span>
                <span>{deliveryCharges.toFixed(2)} €</span>
              </div>
              <div className='cartpage-labels cartpage-total'>
                <span>Total (TVA incluse 0%)</span>
                <span>{calcTotal().toFixed(2)} €</span>
              </div>
            </div>
          </div>
          {/* ---------------------CREDIT CARD INPUT--------------------- */}
          <div className='checkout-container'>
            <div className='checkout-label'>Mode de paiement</div>
            <StripeForm
              cart={cart}
              deliveryAddress={myAddress.subtitle}
              updateCart={updateCart}
              feedback={feedback}
              setFeedback={setFeedback}
              user={user}
              orderAmount={calcTotal().toFixed(2)}
            />
          </div>
        </div>
      </div>
    </Elements>
  );
};

export default Checkout;
