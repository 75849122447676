import Counter from "./counter";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Cart = ({
  myAddress,
  setHide,
  setAgemodal,
  adult,
  cart,
  updateCart,
  feedback,
  setFeedback,
}) => {
  const navigate = useNavigate();
  const [display, setDisplay] = useState(false);

  //Delivery charges;
  const deliveryCharges = (1.8).toFixed(2);

  //Calculate cart SubTotal
  const calcSubTotal = () => {
    let total = 0;
    cart.forEach((item) => {
      total += item.price.amount * item.orderedQty;
    });
    return total.toFixed(2);
  };

  //Truncate .shorten names..text...
  const truncate = (str, length) => {
    return str.substring(0, length) + "...";
  };

  return !cart.length ? (
    <div></div>
  ) : (
    <div>
      <div className="cartbadge" onClick={() => setDisplay(!display)}>
        <div>
          <FontAwesomeIcon icon="shopping-cart" />
        </div>
        <div className="cart-label-bg">
          <div className="cart-subtotal">{calcSubTotal()} €</div>
          <div className="delivery-charges">{`+${deliveryCharges} € Frais de livraison`}</div>
        </div>
      </div>
      <div className={`cart-modal ${display ? "" : "hide"}`}>
        <div className="cart-card">
          <div className="modal-title">
            <p>Panier d'achat</p>
            <p onClick={() => navigate("/cart")} className="cart-expand">
              <FontAwesomeIcon icon="expand-alt" />
            </p>
          </div>
          <div className="cart-items">
            {cart.map((product, index) => {
              return (
                <div className="cart-item" key={product._id}>
                  <div className="cart-img">
                    <img
                      className={`productpic-thumb ${
                        !product.thumbnail ? "hide-thumb" : ""
                      }`}
                      src={product.thumbnail}
                      alt=""
                    />
                  </div>

                  <div
                    className="prodcard-pan"
                    onClick={() => navigate(`/product/${product.sku}`)}
                  >
                    <p className="prodcard-name">
                      {truncate(product.name, 50)}
                    </p>
                    <span className="prodcard-price">
                      {product.price.amount} €{" "}
                    </span>
                  </div>
                  <div className="cart-counter">
                    <Counter
                      adult={adult}
                      setHide={setHide}
                      setAgemodal={setAgemodal}
                      cart={cart}
                      updateCart={updateCart}
                      feedback={feedback}
                      setFeedback={setFeedback}
                      product={product}
                      myAddress={myAddress}
                    />
                  </div>
                </div>
              );
            })}
          </div>

          <div
            className="cartbadge cartbadge-lg"
            onClick={() => navigate("/checkout")}
          >
            <div className="credit-card">
              <FontAwesomeIcon icon="credit-card" />
              <p>CAISSE</p>
            </div>
            <div className="cart-label-bg">
              <div className="cart-subtotal">{calcSubTotal()} €</div>
              <div className="delivery-charges">{`+${deliveryCharges} € Frais de livraison`}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cart;
