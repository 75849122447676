const Footer = () => {
  return (
    <>
      <div className="footer-container">
        <div className="footer-top-pan">
          <p>Mentions légales</p>
          <p>Politique de protection</p>
          <p>Conditions générales de vente</p>
          <p>Carrières</p>
          <p>FAQ</p>
          <p>Presse</p>
        </div>
        <div className="footer-bottom-pan">
          <p>&copy; 2021 Flink SE</p>
        </div>
      </div>
    </>
  );
};

export default Footer;
