const Ordermodal = ({ commande, setCommande, orderModal, setOrderModal }) => {
  //Close modal..function..
  const closeModal = () => {
    setCommande(null);
    setOrderModal(false);
  };

  //Truncate .shorten names..text...
  //   const truncate = (str, length) => {
  //     return str.substring(0, length) + "...";
  //   };

  return (
    <div>
      <div className={`order-modal ${orderModal ? "" : "hide"}`}>
        <div className="order-card">
          <div className="modal-title">
            <p>Détails de la commande</p>
            <p className="btn-close" onClick={closeModal}>
              X
            </p>
          </div>
          <div className="cart-items">
            {commande &&
              commande.map((product, index) => {
                return (
                  <div className="cart-item" key={product._id}>
                    <div className="order-item">
                      <div className="cart-img">
                        <img
                          className={`productpic-thumb ${
                            !product.thumbnail ? "hide-thumb" : ""
                          }`}
                          src={product.thumbnail}
                          alt=""
                        />
                      </div>
                      <div className="prodcard-pan">
                        <p className="prodcard-name">{product.name}</p>
                        <span className="prodcard-price">
                          {product.price.amount} €{" "}
                        </span>
                      </div>{" "}
                    </div>
                    <div className="cart-counter">
                      <p className="qty">{product.orderedQty}</p>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ordermodal;
