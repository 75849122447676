import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate } from "react-router-dom";
import Counter from "../components/counter";
import Header from "../components/header";
import Feedback from "../components/feedback";
import SEO from "../components/seo";

const Cartpage = ({
  token,
  setToken,
  myAddress,
  setHide,
  adult,
  setAgemodal,
  cart,
  updateCart,
  setFeedback,
  feedback,
}) => {
  // -------------------------------------\\
  //Calculate cart SubTotal
  const calcSubTotal = () => {
    let subtotal = 0;
    cart.forEach((item) => {
      subtotal += item.price.amount * item.orderedQty;
    });
    return subtotal;
  };

  // -------------------------------------\\
  //Calculate Total
  const calcTotal = () => {
    let total = calcSubTotal();
    total += deliveryCharges;
    total += caution;
    return total;
  };

  //Temmporary Caution and Delivery charges vars..
  //
  const deliveryCharges = 1.8;
  const caution = 0;

  //Handle to checkout ------------------
  const navigate = useNavigate();
  //
  const toCheckout = () => {
    if (!cart.length) {
      setFeedback("Votre panier est vide.......!");
    } else {
      navigate("/checkout");
    }
  };

  // ---------------------------------------------------------------------------------------
  //Rendered Page.....
  return (
    <div className=''>
      <SEO
        title='Flink by Masood: Cart'
        page='Cart'
        description='Flink project by Masood @ LE REACTEUR, PARIS'
        keywords={`Flink, Le Reacteur, Paris`}
        robots={`index, follow`}
        link={`https://flink.cloudi.fr/`}
        type='website'
        creator='Masood AHMAD'
        image='https://flink.cloudi.fr/logo192.png'
      />
      {/* .....................Header................ */}
      <Feedback feedback={feedback} setFeedback={setFeedback} />
      {/* .....................Header................ */}
      <Header token={token} setToken={setToken} />

      {/* ------------------------------------------ */}
      <div className='container'>
        <div className='page-links'>
          <Link className='page-links' to='/products'>
            &lsaquo; Continuer les achats
          </Link>
        </div>

        <div className='prod-category'>Panier d'achats</div>
        <div className='cart-container'>
          <table className='cart-table'>
            <thead>
              <tr>
                <td colSpan={2}>Vos articles ({cart.length})</td>
                <td>Quantité</td>
                <td>Prix de l'article</td>
                <td>Articles au total</td>
              </tr>
            </thead>
            <tbody>
              {cart.length
                ? cart.map((article) => {
                    return (
                      <tr key={article._id}>
                        <td>
                          <img src={article.thumbnail} alt='' />
                        </td>
                        <td>{article.name}</td>
                        <td className='cartpage-counter'>
                          <Counter
                            product={article}
                            myAddress={myAddress}
                            setHide={setHide}
                            adult={adult}
                            setAgemodal={setAgemodal}
                            cart={cart}
                            updateCart={updateCart}
                            setFeedback={setFeedback}
                          />{' '}
                        </td>
                        <td>{article.price.amount.toFixed(2)} €</td>
                        <td>
                          {(article.price.amount * article.orderedQty).toFixed(
                            2
                          )}{' '}
                          €
                        </td>
                      </tr>
                    );
                  })
                : ''}
            </tbody>
          </table>
          <div className='cartpage-caisse'>
            <div className='cartpage-labels'>
              <span>Sous-total</span>
              <span>{calcSubTotal().toFixed(2)} €</span>
            </div>
            <div className='cartpage-labels'>
              <span>Caution</span> <span>{caution.toFixed(2)} €</span>
            </div>
            <div className='cartpage-labels'>
              <span>Frais de Livraison</span>
              <span>{deliveryCharges.toFixed(2)} €</span>
            </div>
            <div className='cartpage-labels cartpage-total'>
              <span>Total (TVA incluse 0%)</span>
              <span>{calcTotal().toFixed(2)} €</span>
            </div>
            <div onClick={toCheckout} className='cartpage-validate'>
              <FontAwesomeIcon icon='credit-card' />
              CAISSE
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cartpage;
