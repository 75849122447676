import { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import logo2 from "../media/logo2.svg";
import Addressmodal from "../components/addressmodal";
import Navbar from "../components/navbar";
import ProductCard from "../components/productcard";
import Agemodal from "../components/agemodal";
import Feedback from "../components/feedback";
import Cart from "../components/cart";
import Loginbtn from "../components/loginbtn";
import SEO from "../components/seo";

const Search = ({
  token,
  setToken,
  hide,
  setHide,
  adult,
  setAdult,
  agemodal,
  setAgemodal,
  cart,
  updateCart,
  myAddress,
  setMyAddress,
  delay,
  setDelay,
  feedback,
  setFeedback,
  activecat,
  setActivecat,
}) => {
  //
  //From products page.. get search string...
 
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search)

  const str = queryParams.get('q')
  console.log(str);
 
 const navigate = useNavigate();

  const [searchStr, setSearchStr] = useState(str || ""); //Set initial search string from params...
  const [loading, setloading] = useState(false); //Handle asynchronous data fetching from back-end
  const [srchProducts, setSrchProducts] = useState([]); //Search results..
  const [resultCount, setResultCount] = useState(0); //Number of products found..

  //Intial state on page load.........
  useEffect(() => {
    //Fetch search results if string length is greater than 3 ..
    (async () => {
      if (searchStr.length >= 3) {
        try {
          // Invoke api request on search string length
          setloading(true);
          const response = await axios(
            `${process.env.REACT_APP_API}/api/products/search?q=${searchStr}`
          );
          const prods = response.data?.products;
          const count = response.data?.count;
          //Check if search result has some data to display
          if (prods.length) {
            setSrchProducts(prods);
            setResultCount(count);
            setloading(false);
          } else {
            setloading(false);
          }
        } catch (err) {
          setFeedback(err.response?.data?.message);
        }
      }
    })(); //IIFE
    //Cleanup function...
    return () => {
      setSrchProducts([]);
      setResultCount(0);
      setSearchStr("");
    };
  }, []);

  // Product search handling function..
  const productSearch = async (evt) => {
    // evt.preventDefault();

    if (searchStr.length >= 3) {
      navigate(`/search?q=${searchStr}`);
    } else {
       evt.preventDefault();
    }
  };

  // ---------------------------------------------------------------------------------------\\
  //Rendered Page.....
  return loading ? (
    <div className='loading'>
      <img
        src='https://media.giphy.com/media/PUYgk3wpNk0WA/giphy.gif'
        alt='Loading'
      />
    </div>
  ) : (
    <div className='main'>
      <SEO
        title='Flink by Masood: Product Search'
        page='Product Search'
        description='Flink project by Masood @ LE REACTEUR, PARIS'
        keywords={`Flink, Le Reacteur, Paris`}
        robots={`index, follow`}
        link={`https://flink.cloudi.fr/`}
        type='website'
        creator='Masood AHMAD'
        image='https://flink.cloudi.fr/logo192.png'
      />
      {/* .....................Modals............... */}
      <Feedback feedback={feedback} setFeedback={setFeedback} />
      <Agemodal
        setAdult={setAdult}
        agemodal={agemodal}
        setAgemodal={setAgemodal}
      />
      <Addressmodal
        hide={hide}
        setHide={setHide}
        setMyAddress={setMyAddress}
        setDelay={setDelay}
      />
      {/* .....................Header................ */}
      <div className='header'>
        <Link to='/products'>
          <img className='logo2' src={logo2} alt='logo'></img>
        </Link>

        <div className='search-input'>
          <form action='/search' onSubmit={productSearch}>
            <input
              placeholder='Recherche...'
              className='searchbar'
              type='text'
              name='q'
              onChange={(e) => setSearchStr(e.target.value)}
            />
            <FontAwesomeIcon icon='search' className='search-icon' />
          </form>
        </div>

        <div className='navpan'>
          <Cart
            myAddress={myAddress}
            adult={adult}
            setHide={setHide}
            cart={cart}
            updateCart={updateCart}
            feedback={feedback}
            setFeedback={setFeedback}
          />

          <Loginbtn token={token} setToken={setToken} />
        </div>
      </div>

      {/* .....................BODY................ */}
      <div className='prods-pan container'>
        <Navbar
          myAddress={myAddress}
          //   setProducts={setProducts}
          delay={delay}
          setHide={setHide}
          cart={cart}
          loading={loading}
          setloading={setloading}
          showSubs={false}
          activecat={activecat}
          setActivecat={setActivecat}
        />
        {/* ...............Products ................ */}
        <div>
          <div className='page-links'>
            <Link className='page-links' to='/products'>
              &lsaquo; Domicile
            </Link>
          </div>
          <p className='search-results'>il y a {resultCount} résultat(s)</p>
          <ProductCard
            products={srchProducts}
            cart={cart}
            updateCart={updateCart}
            feedback={feedback}
            setFeedback={setFeedback}
            adult={adult}
            setAgemodal={setAgemodal}
            hide={hide}
            setHide={setHide}
            myAddress={myAddress}
          />
        </div>
      </div>
    </div>
  );
};

export default Search;
