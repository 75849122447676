//Feedback component .. show feedback/error on everyback..

const Feedback = ({ feedback, setFeedback }) => {
  return feedback ? (
    <div className="feedback">
      <h6>{feedback}</h6>
      <p className="btn-close" onClick={() => setFeedback(null)}>
        X
      </p>
    </div>
  ) : (
    <div></div>
  );
};

export default Feedback;
