import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import Feedback from "../components/feedback";
import Header from "../components/header";
import SEO from "../components/seo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Adminlogin = ({ token, setToken, feedback, setFeedback }) => {
  const navigate = useNavigate();

  const [password, setPassword] = useState(null);

  //Admin login handling
  const handleLogin = (evt) => {
    evt.preventDefault();

    if (token) {
      navigate("/backoffice");
    } else if (password === "HelloWorld!") {
      Cookies.set("Token", password, { expires: 7 });
      setToken(password);
      navigate("/backoffice");
    } else {
      setFeedback("Invalid credentials....!");
    }
  };

  return (
    <div>
      <SEO
        title='Flink by Masood: Admin login'
        page='Admin login'
        description='Flink project by Masood @ LE REACTEUR, PARIS'
        keywords={`Flink, Le Reacteur, Paris`}
        robots={`index, follow`}
        link={`https://flink.cloudi.fr/`}
        type='website'
        creator='Masood AHMAD'
        image='https://flink.cloudi.fr/logo192.png'
      />
      <Feedback feedback={feedback} setFeedback={setFeedback} />
      <Header token={token} setToken={setToken} />
      <div className='container'>
        <div className='admin-card'>
          <form className='admin-form' action='' onSubmit={handleLogin}>
            <input
              className='searchbar admin-input'
              placeholder='Mot de passe'
              type='password'
              name='password'
              id='password'
              onChange={(evt) => setPassword(evt.target.value)}
            />

            <button className='btn-pay' type='submit' value='Submit'>
              Admin
              <FontAwesomeIcon icon='sign-in-alt' />
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Adminlogin;
