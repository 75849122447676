import { useParams, Link } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import logo2 from "../media/logo2.svg";
import Agemodal from "../components/agemodal";
import Feedback from "../components/feedback";
import Cart from "../components/cart";
import Searchmodal from "../components/searchmodal";
import Addressmodal from "../components/addressmodal";
import Loginbtn from "../components/loginbtn";
import Counter from "../components/counter";
import SEO from "../components/seo";

const Product = ({
  myAddress,
  setMyAddress,
  setDelay,
  hide,
  setHide,
  token,
  setToken,
  adult,
  setAdult,
  agemodal,
  setAgemodal,
  cart,
  updateCart,
  feedback,
  setFeedback,
}) => {
  const { id } = useParams();

  const [product, setProduct] = useState(); //Product details state
  const [isloading, setLoading] = useState(true); //Loading isloading toggle

  // Query to get product data
  useEffect(() => {
    let active = true;
    (async () => {
      const productData = await axios(
        `${process.env.REACT_APP_API}/api/products/${id}`
      );
      if (active) {
        setProduct(productData.data);
        setLoading(false);
      }
    })();
    return () => {
      active = false;
      setProduct(null);
    };
  }, [id]);

  // -----------------------------------------------------------------------------

  return isloading ? (
    <div className='loading'>
      <img
        src='https://media.giphy.com/media/PUYgk3wpNk0WA/giphy.gif'
        alt='Loading'
      />
    </div>
  ) : (
    <div className=''>
      <SEO
        title={`Flink by Masood: ${product.name}`}
        page={`${product.name}`}
        description={product.description.replaceAll('#', '')}
        keywords={`Flink, Le Reacteur, Paris`}
        robots={`index, follow`}
        link={`https://flink.cloudi.fr/`}
        type='website'
        creator='Masood AHMAD'
        image='https://flink.cloudi.fr/logo192.png'
      />
      {/* -------------Modals------------------------- */}
      <Feedback feedback={feedback} setFeedback={setFeedback} />
      <Agemodal
        setAdult={setAdult}
        agemodal={agemodal}
        setAgemodal={setAgemodal}
      />
      <Addressmodal
        hide={hide}
        setHide={setHide}
        setMyAddress={setMyAddress}
        setDelay={setDelay}
      />
      {/* .....................Header................. */}
      <div className='header'>
        <Link to='/products'>
          <img className='logo2' src={logo2} alt='logo'></img>
        </Link>
        <Searchmodal
          cart={cart}
          adult={adult}
          setAgemodal={setAgemodal}
          updateCart={updateCart}
          setFeedback={setFeedback}
        />
        <div className='navpan'>
          <Cart
            cart={cart}
            updateCart={updateCart}
            feedback={feedback}
            setFeedback={setFeedback}
            adult={adult}
            setAgemodal={setAgemodal}
            myAddress={myAddress}
            setHide={setHide}
          />

          <Loginbtn token={token} setToken={setToken} />
        </div>
      </div>
      <div className='container'>
        {/* .....................Product Details.............. */}

        <div className='page-links'>
          <Link className='page-links' to='/products'>
            Home &nbsp;
          </Link>
          <span>&rsaquo; {product.name}</span>
        </div>

        <div className='product-page-pan'>
          <div>
            <img src={product.images[0]} alt='product' />
          </div>
          <div className='product-block'>
            <h4>{product.name}</h4>
            <h3>{product.price.amount.toFixed(2)} €</h3>
            {product.quantity ? (
              <Counter
                cart={cart}
                updateCart={updateCart}
                feedback={feedback}
                setFeedback={setFeedback}
                product={product}
                adult={adult}
                setAgemodal={setAgemodal}
                myAddress={myAddress}
                setHide={setHide}
              />
            ) : (
              <div className='outofstock'>épuisé(e)</div>
            )}
          </div>
        </div>
        <div className='product-description'>
          <h5>Description</h5>
          <p>{product.description.replaceAll('#', '')}</p>
        </div>
      </div>
    </div>
  );
};

export default Product;
