import { useState } from "react";
import { useNavigate } from "react-router";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Counter from "./counter";

const Searchmodal = ({
  setHide,
  myAddress,
  adult,
  setAgemodal,
  cart,
  updateCart,
  setFeedback,
}) => {
  const navigate = useNavigate();
  //
  //Set initial state..

  const [searchStr, setSearchStr] = useState("");
  const [srchProducts, setSrchProducts] = useState([]); //Search results..
  const [display, setDisplay] = useState(false); //Toggle modal display..
  const [moretocome, setMoretocome] = useState(false); //Toggle ..Voir tous les resultats.

  // Product search handling function..
  const productSearch = async (evt) => {
    const newstr = await evt.target.value;
    setSearchStr(newstr); //to be used to fech more results on dedicated search page.
    if (newstr.length >= 3) {
      try {
        // Invoke api request on search string length
        const response = await axios(
          `${process.env.REACT_APP_API}/api/products/search?q=${newstr}&page_limit=5`
        );

        const prods = response.data?.products;
        const more = response.data?.moretocome;
        //Check if search result has some data to display
        if (prods.length) {
          setSrchProducts(prods);
          setDisplay(true);
          setMoretocome(more); //results more than 5/or not
        }
      } catch (err) {
        setFeedback(err.response.data.message);
      }
    } else {
      setSrchProducts([]);
      setDisplay(false);
    }
  };

  //Handle enter key event ..go to search page..
  const handleEnter = (evt) => {
    if (evt.key === "Enter") {
      navigate(`/search?q=${searchStr}`);
    }
  };

  //Handle See more results..go to search page..
  const seeMore = () => {
    navigate(`/search?q=${searchStr}`);
  };

  //Truncate .shorten names..text...
  const truncate = (str, length) => {
    return str.substring(0, length) + "...";
  };

  return (
    <div className="search-input">
      <div className="search-pan">
        <input
          className="searchbar"
          type="text"
          placeholder="Recherche..."
          onChange={(evt) => productSearch(evt)}
          onKeyPress={handleEnter}
        />
        <FontAwesomeIcon icon="search" className="search-icon" />
      </div>
      <div className={`cart-modal search-modal ${display ? "" : "hide"}`}>
        <div className="cart-card">
          <div className="cart-items">
            {srchProducts &&
              srchProducts.map((product, index) => {
                return (
                  <div className="cart-item" key={product._id}>
                    <div className="cart-img">
                      <img
                        className={`productpic-thumb ${
                          !product.thumbnail ? "hide-thumb" : ""
                        }`}
                        src={product.thumbnail}
                        alt=""
                      />
                    </div>

                    <div
                      className="prodcard-pan"
                      onClick={() => navigate(`/product/${product.sku}`)}
                    >
                      <p className="prodcard-name">
                        {truncate(product.name, 50)}
                      </p>
                      <span className="prodcard-price">
                        {product.price.amount} €{" "}
                      </span>
                    </div>
                    <div className="cart-counter">
                      <Counter
                        adult={adult}
                        setAgemodal={setAgemodal}
                        cart={cart}
                        updateCart={updateCart}
                        setFeedback={setFeedback}
                        product={product}
                        setHide={setHide}
                        myAddress={myAddress}
                      />
                    </div>
                  </div>
                );
              })}
          </div>

          <div className={`moretocome ${moretocome ? "" : "hide"}`}>
            <div className="seemore" onClick={seeMore}>
              Voir tous les résultats &nbsp; &gt;{" "}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Searchmodal;
