import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import icons from "../media/icons/icons.json";
import axios from "axios";

const Navbar = ({
  showSubs,
  myAddress,
  delay,
  cart,
  setProducts,
  setHide,
  loading,
  setloading,
  setActivecat,
  activecat,
}) => {
  const navigate = useNavigate();
  // const [activecat, setactivecat] = useState(); // Active category..to keep track in navigation
  const [loads, setloads] = useState(true); //local loading state for initial useEffect..
  const [catslist, setCatslist] = useState([]); // Fetch Categories and sub-categories list from DB
  const [displaysubs, setDisplaysubs] = useState(true);

  //Intial state on navbar load.........
  useEffect(() => {
    (async () => {
      //Fetch data for categories and sub-categories....left vertical nav
      try {
        const catsList = await axios(
          `${process.env.REACT_APP_API}/api/categories`
        );
        const catData = catsList.data;
        setCatslist(catData);
        setloads(false);
      } catch (err) {
        console.log(err);
      }
    })(); //IIFE

    return () => {
      setCatslist([]);
    };
  }, []);

  //Fetch category products on click ......
  const fetchProducts = async (cat) => {
    //On pages, other than products page .. navigte to products page
    if (cat === activecat) {
      return;
    }

    if (!showSubs) {
      setActivecat(cat);
      return navigate(`/products`);
    }
    //Category search on products page
    try {
      setloading(true);
      const categoryProds = await axios.post(
        `${process.env.REACT_APP_API}/api/categories/products`,
        cat
      );
      const prodData = categoryProds.data;
      setProducts(prodData);

      if (cat !== activecat){
        setDisplaysubs(true)
      } else {
        setDisplaysubs(!displaysubs);
      }

      setActivecat(cat);
      setloading(false);
    } catch (err) {
      console.log(err);
    }
  };

  //Function to calculate total derlivery delay.
  //Delivery delay + order preparation delay
  const totalDelay = () => {
    //total number of seconds ..15 seconds per unique ref
    const totalSeconds = delay + cart.length * 15;
    if (totalSeconds < 60) {
      //Return one minute in case duration is less than 60 seconds
      return 1;
    } else {
      return Math.floor(totalSeconds / 60);
    }
  };

  //To products page.. get special categories
  const defaultProds = () => {
    setActivecat(null);
    navigate("/products");
  };

  //
  //Rendered Page.....
  return  (
    <div className="navbar">
      <div className="address" onClick={() => setHide(false)}>
        {delay && (
          <div>
            <p>{totalDelay()}</p>
            <p>min</p>
          </div>
        )}
        <p className="address-text">
          {myAddress.subtitle || "Choisir une adresse de livraison."}
        </p>
      </div>
      <div className="cat-label">NAVIGATION</div>
      <div className="sub-category" onClick={defaultProds}>
        <FontAwesomeIcon icon="home" /> Accueil
      </div>
      <div className="cat-label">CATEGORIES</div>
      <div className="nav-categories">
        {catslist.map((cat) => {
          return (
            <div key={`div${cat._id}`}>
              <p
                className={`category`}
                key={cat._id}
                onClick={() => fetchProducts(cat)}
              >
                <span className={`${icons[cat.name]}`}></span>{" "}
                <span>{cat.name}</span>
              </p>
              {/* Display sub-category only if actve.. hidden on search page.. */}
              <ul className={displaysubs ? "" : "hide"}>
                {showSubs &&
                  cat._id === activecat?._id &&
                  cat.sub_categories.map((item) => {
                    return (
                      <li className="sub-category" key={item._id}>
                        {item.name}
                      </li>
                    );
                  })}
              </ul>
            </div>
          );
        })}
      </div>
      <div className="sub-category faq">FAQ</div>
      <div className="sub-category">Support</div>
    </div>
  );
};

export default Navbar;
