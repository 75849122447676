import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Loginbtn = ({ token, setToken }) => {
  const navigate = useNavigate();

  //handle logout
  const logout = () => {
    Cookies.remove("Token");
    setToken(null);
    navigate("/products");
  };

  return token ? (
    <div className="btn-pan">
      <div className="btn-login" onClick={() => navigate("/backoffice")}>
        Admin
      </div>
      <FontAwesomeIcon
        className="btn-logout"
        onClick={logout}
        icon="sign-out-alt"
      />
    </div>
  ) : (
    <div className="btn-login" onClick={() => navigate("/adminlogin")}>
      Admin
      <FontAwesomeIcon icon="sign-in-alt" />
    </div>
  );
};

export default Loginbtn;
