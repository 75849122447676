import axios from "axios";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "../components/header";
import Feedback from "../components/feedback";
import Ordermodal from "../components/ordermodal";
import SEO from "../components/seo";


const Backoffice = ({ token, setToken, feedback, setFeedback }) => {
  const navigate = useNavigate();
  const [orders, setorders] = useState([]); //orders data..
  const [loading, setloading] = useState(false);
  const [commande, setCommande] = useState([]); // to display order details in modal
  const [orderModal, setOrderModal] = useState(false); //Modal display toggle..
  

  //Fetch orders data on page load....
  useEffect(() => {
    let active = true;
    setloading(true);
    (async () => {
      //Token verification....
      if (!token) {
        setloading(false);
        navigate("/adminlogin");
      } else {
        const orderData = await axios(
          `${process.env.REACT_APP_API}/api/backoffice`
        );
        if (active) {
          setorders(orderData.data);
          setloading(false);
        }
      }
    })(); //IIFE
    return () => {
      active = false;
      setorders([]);
    };
  }, [token]);

  //Date formatting function..
  const formatDate = (secs) => {
    const newDate = new Date(Number(secs));
    return newDate.toLocaleDateString();
  };

  //Display detailed order function..
  const displayDetails = (order) => {
    setCommande(order.cart);
    setOrderModal(true);
  };

  //
  // --------------------------------------------------------------------->
  //Rendered page elements

  return loading ? (
    <div className='loading'>
      <img
        src='https://media.giphy.com/media/PUYgk3wpNk0WA/giphy.gif'
        alt='Loading'
      />
    </div>
  ) : (
    <div className=''>
      <SEO
        title='Flink by Masood: Backoffice'
        page='Backoffice'
        description='Flink project by Masood @ LE REACTEUR, PARIS'
        keywords={`Flink, Le Reacteur, Paris`}
        robots={`index, follow`}
        link={`https://flink.cloudi.fr/`}
        type='website'
        creator='Masood AHMAD'
        image='https://flink.cloudi.fr/logo192.png'
      />
      <Feedback feedback={feedback} setFeedback={setFeedback} />
      <Ordermodal
        commande={commande}
        setCommande={setCommande}
        orderModal={orderModal}
        setOrderModal={setOrderModal}
      />
      <Header token={token} setToken={setToken} />
      <div className='container'>
        <div className='page-links'>
          <Link className='page-links' to='/products'>
            &lsaquo; Home
          </Link>
        </div>
        <div className='back-pan'>
          <div className='prod-category'>{`Vos ${orders.length} commande(s)`}</div>
          <table className='back-table'>
            <thead>
              <tr className='back-row'>
                <td>Date</td>
                <td>Nom</td>
                <td>Prénom</td>
                <td>Email</td>
                <td>Total</td>
              </tr>
            </thead>
            <tbody>
              {orders.length ? (
                orders.map((order) => {
                  return (
                    <tr
                      className='order-row'
                      key={order._id}
                      onClick={() => {
                        displayDetails(order);
                      }}
                    >
                      <td>{formatDate(order.orderdate)}</td>
                      <td>{order.buyer.lastname}</td>
                      <td>{order.buyer.firstname}</td>
                      <td>{order.buyer.email}</td>
                      <td>{(order.amount / 100).toFixed(2)} €</td>
                    </tr>
                  );
                })
              ) : (
                <tr></tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Backoffice;
