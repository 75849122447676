import { useState } from "react";
import axios from "axios";
import riders from "../media/riders_portrait.jpg";

const Addressmodal = ({ hide, setHide, setMyAddress, setDelay }) => {
  // Initial state setting
  const [addresses, setAddresses] = useState(null); //Address search results
  const [feedback, setFeedback] = useState(""); // Local feedback /error display

  // Address search handling function..
  const addressSearch = async (evt) => {

    const newstr = await evt.target.value;

    if (newstr.length >= 3) {
      try {
        // Invoke api request on search string lenght
        const response = await axios(
          `${process.env.REACT_APP_API}/api/locations?q=${newstr}`
        );

        const Adds = response.data;
        setAddresses(Adds);
      } catch (err) {
        setFeedback(err.response?.data?.message);
      }
    } else {
      setAddresses(null);
    }
  }



  //Set devliery adress ... handling function..................
  const handleDeliveryAddress = async (elem) => {
    // Invoke address validation function
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API}/api/validate`,
        elem
      );
      if (response.data?.valid) {
        //   Address is deliveralbe...
        //Store address et delay in local storage..
        localStorage.localAddress = JSON.stringify(elem);
        localStorage.delay = JSON.stringify(response.data.duration);
        //   set address and delay state..
        setMyAddress(elem);
        setDelay(response.data.duration);
        setAddresses(null);
        setFeedback("");
        //Hide carrousel
        setHide(true);
      } else {
        setAddresses(null);
        setFeedback(response.data?.message);
      }
    } catch (err) {
      setFeedback(err.response?.data?.message);
    }
  };

  //close modal function
  const closeModal = () => {
    setFeedback(null);
    setAddresses(null);
    setHide(true);
  };

  //Rendered modal...............

  return (
    <div className={`modal-container ${hide ? "hide" : ""}`}>
      <div className="modal-pan-ad">
        <div>
          <img className="img-riders" src={riders} alt="riders"></img>
        </div>
        <div className="modal-pan-right">
          <div className="modal-title">
            <h3>Pour commencer, vérifions votre adresse</h3>
            <p className="btn-close" onClick={closeModal}>
              X
            </p>
          </div>
          <div className="address-ip">
            <p>
              Veuillez ajouter votre adresse exacte pour voir les produits qui
              peuvent vous être livrés en 10 minutes.
            </p>
            <input
              className="address-input"
              placeholder="Rue et numéro de domicile"
              type="text"
              onChange={(evt) => addressSearch(evt)}
              onAbort={(evt) => (evt.target.value = "")}
            />
          </div>
          <div className="addresses">
            {addresses ? (
              addresses.map((elem, index) => {
                return (
                  <div
                    className="ad-result"
                    key={elem.coordinates.latitude}
                    onClick={() => handleDeliveryAddress(elem)}
                  >
                    <p className="adr-title">{elem.title}</p>
                    <p>{elem.subtitle}</p>
                  </div>
                );
              })
            ) : (
              <p>{feedback}</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Addressmodal;
