const Counter = ({
  myAddress,
  setHide,
  adult,
  setAgemodal,
  product,
  cart,
  updateCart,
  setFeedback,
}) => {
  // --------------------------------------->
  //Add product in the cart..
  const addToCart = (evt, setHide) => {
    evt.stopPropagation();
    //check if address is set
    if (!myAddress || !myAddress.subtitle) {
      return setHide(false);
    }

    //check if alcoholic product eligibility
    if (product.alcohol === true) {
      if (!adult) {
        setAgemodal(true);
        return;
      }
    }
    //Alcohol filter passed.. so proceed.
    const newCart = [...cart];
    //check if product already in a cart.. in that case increment quantity
    const found = newCart.find((cartitem) => cartitem._id === product._id);

    //Verify stock.. and add if stock available

    if (found === undefined && product.quantity >= 1) {
      product.orderedQty = 1;
      newCart.push(product);
    } else if (found && found.orderedQty < product.quantity) {
      found.orderedQty++;
    } else {
      setFeedback("La limite de stock atteinte.....!");
      return;
    }
    // localStorage.localCart = JSON.stringify(newCart);
    localStorage.localCart = JSON.stringify(newCart);
    //update cart state and local storage cart..
    updateCart(newCart);
  };

  // --------------------------------------->
  //Remove an itme for the basket..
  const removeItem = (evt) => {
    evt.stopPropagation();
    //Find index of product to be removed...
    const index = cart.findIndex((cartitem) => cartitem._id === product._id);
    //product in found.. so let's proceed...
    if (index !== -1) {
      if (cart[index].orderedQty <= 1) {
        //last item in the cart..so remove the item..
        const newCart = [...cart];
        newCart.splice(index, 1);
        localStorage.localCart = JSON.stringify(newCart);
        updateCart(newCart);
      } else {
        //More than one articles ..remove one from quantity.
        const newCart = [...cart];
        newCart[index].orderedQty--;
        localStorage.localCart = JSON.stringify(newCart);
        updateCart(newCart);
      }
    }
  };

  //Product qty in the cart..
  const cartQty = () => {
    const found = cart.find((cartitem) => cartitem._id === product._id);
    if (found) {
      return found.orderedQty;
    }
    return 0;
  };

  return cartQty() ? (
    <div className="counter" key={product._id}>
      <p onClick={(evt) => removeItem(evt)}>-</p>
      {/* <FontAwesomeIcon onClick={removeItem} icon="minus-circle" /> */}
      <p className="cart-qty">{cartQty()}</p>
      {/* <span>{cart && cart[index].quantity}</span> */}

      {/* <FontAwesomeIcon onClick={addToCart} icon="plus-circle" /> */}
      <p onClick={(evt) => addToCart(evt, setHide)}>+</p>
    </div>
  ) : (
    <div className="counter counter-sm" key={`plus${product._id}`}>
      <p onClick={(evt) => addToCart(evt, setHide)}>+</p>
    </div>
  );
};

export default Counter;
