import "./App.css";
import "./media/icons/fonts-icons.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useState } from "react";
import Cookies from "js-cookie";

// Import pages and components..
import Home from "./pages/home";
import Products from "./pages/products";
import Product from "./pages/product";
import Search from "./pages/search";
import Cartpage from "./pages/cartpage";
import Checkout from "./pages/checkout";
import Adminlogin from "./pages/adminlogin";
import Backoffice from "./pages/backoffice";

//Stripe Load function....
import { loadStripe } from "@stripe/stripe-js";

//Fontawesome icons import
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faSignOutAlt,
  faHome,
  faArrowRight,
  faSearch,
  faCreditCard,
  faExpandAlt,
  faSignInAlt,
  faShoppingCart,
} from "@fortawesome/free-solid-svg-icons";
library.add(
  faSignOutAlt,
  faHome,
  faArrowRight,
  faSearch,
  faSignInAlt,
  faShoppingCart,
  faExpandAlt,
  faCreditCard
);

//Stripe Promise .. Public key..@paristalkies
const stripePromise = loadStripe(
  "pk_test_51K6eNOGMW7OohzoaRnwa5ucHBsfYkjQDwU6ynoC9kHKgUDQXV8x7XX05InGsUwvPifYhYMSABe4R4wn1oeKuROPR00cnUzSzzM"
);

//
//------------------------------------------------------------------------------------------------------>
//Quite a bit of prop drilling to expect... Global state solutions like Redux and Context/reduce might
//be better solutions but that's beyond the scope of this project and training ..Happy reading..
//undry code at some places...just to avoid multiple-level prop drilling.... !!!!!
//------------------------------------------------------------------------------------------------------>
//

function App() {
  // Set initial states.. cart, modals states...
  const [feedback, setFeedback] = useState(null); //Global error hanlding feedback state...
  const [adult, setAdult] = useState(false); //Ask if buyer is adult...for alcohol products
  const [agemodal, setAgemodal] = useState(false); // Modal for adult/age confirmation..
  const [activecat, setActivecat] = useState(null); // Displayed product category..
  const [cart, updateCart] = useState(
    localStorage.localCart ? JSON.parse(localStorage.localCart) : []
  ); //Get cart details from local storge . if any
  const [myAddress, setMyAddress] = useState(
    localStorage.localAddress ? JSON.parse(localStorage.localAddress) : {}
  ); //Get an adrees ..if locally saved
  const [delay, setDelay] = useState(
    localStorage.delay ? JSON.parse(localStorage.delay) : null
  ); //Delivery delay.. in seconds
  const [hide, setHide] = useState(myAddress || false); //Address modal display??
  const [token, setToken] = useState(Cookies.get("Token") || null); // Admin login token.. to be decided..

  // ---------------------------------------------------------------------------\\

  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route
            // ---------------------Productsssss route-----------------------------\\
            path="/products"
            element={
              <Products
                token={token}
                setToken={setToken}
                cart={cart}
                updateCart={updateCart}
                myAddress={myAddress}
                setMyAddress={setMyAddress}
                delay={delay}
                setDelay={setDelay}
                feedback={feedback}
                setFeedback={setFeedback}
                adult={adult}
                setAdult={setAdult}
                agemodal={agemodal}
                setAgemodal={setAgemodal}
                hide={hide}
                setHide={setHide}
                activecat={activecat}
                setActivecat={setActivecat}
              />
            }
          />
          <Route
            // ---------------------Product route-----------------------------\\
            path="/product/:id"
            element={
              <Product
                token={token}
                setToken={setToken}
                cart={cart}
                updateCart={updateCart}
                myAddress={myAddress}
                setMyAddress={setMyAddress}
                delay={delay}
                setDelay={setDelay}
                feedback={feedback}
                setFeedback={setFeedback}
                adult={adult}
                setAdult={setAdult}
                agemodal={agemodal}
                setAgemodal={setAgemodal}
                hide={hide}
                setHide={setHide}
              />
            }
          />
          <Route
            // ---------------------Search route-----------------------------\\
            path="/search"
            element={
              <Search
                token={token}
                setToken={setToken}
                cart={cart}
                updateCart={updateCart}
                myAddress={myAddress}
                setMyAddress={setMyAddress}
                delay={delay}
                setDelay={setDelay}
                feedback={feedback}
                setFeedback={setFeedback}
                adult={adult}
                setAdult={setAdult}
                agemodal={agemodal}
                setAgemodal={setAgemodal}
                hide={hide}
                setHide={setHide}
                activecat={activecat}
                setActivecat={setActivecat}
              />
            }
          />
          <Route
            // ---------------------Cart route-----------------------------\\
            path="/cart"
            element={
              <Cartpage
                token={token}
                setToken={setToken}
                cart={cart}
                updateCart={updateCart}
                adult={adult}
                myAddress={myAddress}
                setHide={setHide}
                setAgemodal={setAgemodal}
                setFeedback={setFeedback}
                feedback={feedback}
              />
            }
          />
          <Route
            // ---------------------Checkout route-----------------------------\\
            path="/checkout"
            element={
              <Checkout
                token={token}
                setToken={setToken}
                myAddress={myAddress}
                stripePromise={stripePromise}
                cart={cart}
                updateCart={updateCart}
                feedback={feedback}
                setFeedback={setFeedback}
              />
            }
          />
          <Route
            // ---------------------Login route-----------------------------\\
            path="/adminlogin"
            element={
              <Adminlogin
                token={token}
                setToken={setToken}
                feedback={feedback}
                setFeedback={setFeedback}
              />
            }
          />
          <Route
            // ---------------------Backoffice route-----------------------------\\
            path="/backoffice"
            element={
              <Backoffice
                token={token}
                setToken={setToken}
                feedback={feedback}
                setFeedback={setFeedback}
              />
            }
          />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
