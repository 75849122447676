import { Link } from "react-router-dom";
import logo from "../media/logo.svg";
import parismap from "../media/map.jpg";
import phoneimg from "../media/phone.webp";
import Footer from "../components/footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SEO from "../components/seo";

const Home = () => {
  return (
    <div className='home-container'>
      <SEO
        title='Flink by Masood: Home'
        page='Home'
        description='Flink project by Masood @ LE REACTEUR, PARIS'
        keywords={`Flink, Le Reacteur, Paris`}
        robots={`index, follow`}
        link={`https://flink.cloudi.fr/`}
        type='website'
        creator='Masood AHMAD'
        image='https://flink.cloudi.fr/logo192.png'
      />
      <div className='head-pan home-pan'>
        <p>
          Postule pour devenir coursier &#38; reçois un bonus, -20% sur tes
          courses, et bien plus encore !
        </p>
        <div className='btn-apply'>Postuler</div>
      </div>
      <div className='top-pan'>
        <div className='link-pan'>
          <img className='logo-home' src={logo} alt='logo'></img>
          <h1>+2.000 produits du quotidien livrés en 10 minutes</h1>
          <p>
            Vos marques préférées à prix supermarché. Livraison 7j/7 de 8h à
            minuit.
          </p>
          <div>
            <Link className='btn-products' to='/products'>
              Acheter maintenant &#160;
              <FontAwesomeIcon icon='arrow-right' />
            </Link>
          </div>
        </div>
        <div className='img-home-pan'>
          <img className='img-home' src={phoneimg} alt='home'></img>
        </div>
      </div>

      <h2 className='home-pan cities'>Nous sommes là</h2>
      <div className='home-pan map-container'>
        <div className='cities-container'>
          <select
            name='city-selector'
            id='city-selector'
            className='city-selector'
            disabled
          >
            <option value='Paris'>Paris</option>
          </select>
          <div>
            <span>Vous ne trouvez pas votre ville ? </span>
            <span>
              Inscrivez-vous maintenant pour être prévenus quand Flink débarque
              chez vous
            </span>
          </div>
        </div>
        <div className='map-pan'>
          <img className='paris-map' src={parismap} alt='Paris map'></img>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Home;
