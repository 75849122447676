import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import logo2 from "../media/logo2.svg";
import Addressmodal from "../components/addressmodal";
import Navbar from "../components/navbar";
import ProductCard from "../components/productcard";
import Agemodal from "../components/agemodal";
import Feedback from "../components/feedback";
import Cart from "../components/cart";
import Searchmodal from "../components/searchmodal";
import Loginbtn from "../components/loginbtn";
import SEO from "../components/seo";

const Products = ({
  token,
  setToken,
  hide,
  setHide,
  adult,
  setAdult,
  agemodal,
  setAgemodal,
  cart,
  updateCart,
  myAddress,
  setMyAddress,
  delay,
  setDelay,
  feedback,
  setFeedback,
  activecat,
  setActivecat,
}) => {
  const [loading, setloading] = useState(true); //Handle asynchronous data fetching from back-end
  const [products, setProducts] = useState([]); // SET products and categories from DB

  //Intial state on page load.........
  useEffect(() => {
    (async () => {
      try {
        setloading(true);
        let prodData;
        //if catgegory provided as params.. get that category
        if (activecat) {
          const categoryProds = await axios.post(
            `${process.env.REACT_APP_API}/api/categories/products`,
            activecat
          );
          prodData = categoryProds.data;
          //Fetch special categories and corresponding products ..
        } else {
          const specialProds = await axios(
            `${process.env.REACT_APP_API}/api/categories/special`
          );
          prodData = specialProds.data;
        }

        setProducts(prodData);

        setloading(false);
      } catch (err) {
        console.log(err);
      }
    })(); //IIFE

    return () => {
      setProducts([]);
    };
  }, [activecat]);

  //Rendered Page.....
  return (
    <div className='main'>
      <SEO
        title='Flink by Masood: Product Categories'
        page='Categories'
        description='Flink project by Masood @ LE REACTEUR, PARIS'
        keywords={`Flink, Le Reacteur, Paris`}
        robots={`index, follow`}
        link={`https://flink.cloudi.fr/`}
        type='website'
        creator='Masood AHMAD'
        image='https://flink.cloudi.fr/logo192.png'
      />
      {/* .....................Modals............... */}
      <Feedback feedback={feedback} setFeedback={setFeedback} />
      <Agemodal
        setAdult={setAdult}
        agemodal={agemodal}
        setAgemodal={setAgemodal}
      />
      <Addressmodal
        hide={hide}
        setHide={setHide}
        setMyAddress={setMyAddress}
        setDelay={setDelay}
      />
      {/* .....................Header................ */}
      <div className='header'>
        <Link to='/products'>
          <img className='logo2' src={logo2} alt='logo'></img>
        </Link>
        <Searchmodal
          myAddress={myAddress}
          setHide={setHide}
          cart={cart}
          adult={adult}
          setAgemodal={setAgemodal}
          updateCart={updateCart}
          setFeedback={setFeedback}
        />
        <div className='navpan'>
          {cart.length !== 0 && (
            <Cart
              myAddress={myAddress}
              adult={adult}
              setAgemodal={setAgemodal}
              setHide={setHide}
              cart={cart}
              updateCart={updateCart}
              feedback={feedback}
              setFeedback={setFeedback}
            />
          )}

          <Loginbtn token={token} setToken={setToken} />
        </div>
      </div>

      {/* .....................BODY................ */}
      <div className='prods-pan container'>
        <Navbar
          myAddress={myAddress}
          setProducts={setProducts}
          delay={delay}
          setHide={setHide}
          cart={cart}
          loading={loading}
          setloading={setloading}
          activecat={activecat}
          setActivecat={setActivecat}
          showSubs={true}
        />
        {/* ...............Products ................ */}
        <div>
          <div className='page-links'>
            <span className='page-links' onClick={() => setActivecat(null)}>
              Domicile &nbsp;
            </span>
            <span>
              &rsaquo; {activecat ? activecat.name : 'Nouveautés de la semaine'}
            </span>
          </div>

          {loading ? (
            <div className='loading'>
              <img
                src='https://media.giphy.com/media/PUYgk3wpNk0WA/giphy.gif'
                alt='Loading'
              />
            </div>
          ) : (
            products.map((category, index) => {
              return (
                <div key={category._id}>
                  <div className='prod-category'>{category.name}</div>
                  <ProductCard
                    products={category.products}
                    cart={cart}
                    updateCart={updateCart}
                    feedback={feedback}
                    setFeedback={setFeedback}
                    adult={adult}
                    setAgemodal={setAgemodal}
                    hide={hide}
                    setHide={setHide}
                    myAddress={myAddress}
                  />
                </div>
              );
            })
          )}
        </div>
      </div>
    </div>
  );
};

export default Products;
