import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { useState } from "react";
import axios from "axios";

const StripeForm = ({
  cart,
  user,
  deliveryAddress,
  orderAmount,
  setFeedback,
  updateCart,
  feedback,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [completed, setcompleted] = useState(false); //State shows payment success/failure..

  //Stripe payment handling function...
  const handlePayment = async (event) => {
    event.preventDefault();
    //Basic data validation...
    if (
      !user.id ||
      !user.email ||
      !user.firstname ||
      !user.lastname ||
      !user.tel ||
      !deliveryAddress
    ) {
      return setFeedback("Veuillez renseigner vos informations personnelles.");
    } else if (!cart.length) {
      return setFeedback("Votre panier est vide...");
    }

    const cardNumber = elements.getElement(CardElement);

    try {
      const stripeResponse = await stripe.createToken(cardNumber, {
        name: user.id,
      });
      const stripeToken = stripeResponse.token.id;
      //Finalise transaxtion with backend..
      const response = await axios.post(
        `${process.env.REACT_APP_API}/api/checkout`,
        {
          stripeToken,
          user,
          orderAmount,
          cart,
          deliveryAddress,
        }
      );
      if ((response.data.status = "scceeded")) {
        setFeedback("Nous avons bien reçu votre commande...");
        updateCart([]);
        setcompleted(true);
      }
    } catch (err) {
      console.log(err);
      setFeedback(err.response?.data?.message);
    }
  };

  // CARD input ..Feedback rendering..

  return !completed ? (
    <>
      <form className="stripe-form" action="" onSubmit={handlePayment}>
        <CardElement className="perso-info" />
        <button className="btn-pay" type="submit">
          Payer <FontAwesomeIcon icon="credit-card" />
        </button>
      </form>
    </>
  ) : (
    <p className="">{feedback}</p>
  );
};

export default StripeForm;
